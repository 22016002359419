<template>
  <div>
    <MiniFooter>
      <!-- <template v-slot:left>
        <li class="list-inline-item"><router-link to="/privacy-policy">Privacy Policy</router-link></li>
        <li class="list-inline-item"><router-link to="/terms-of-service">Terms of Use</router-link></li>
      </template> -->
      <template v-slot:right>
        Copyright 2022 <router-link to="/"></router-link>
      </template>
    </MiniFooter>
  </div>
</template>
<script>
import MiniFooter from '../../components/core/footer/MiniFooter'
import { APPNAME } from '../../config/pluginInit'
export default {
  name: 'LayoutFooter',
  components: {
    MiniFooter
  },
  mounted () {
  },
  data () {
    return {
      appName: APPNAME
    }
  }
}
</script>
